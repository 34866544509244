<template>
  <div class="not-found">
    <h1 class="not-found__title">Error 404</h1>
    <div class="not-found__text text">Page not found</div>
    <ButtonBase class="not-found__btn" @click="goToDashboard">
      Dashboard
    </ButtonBase>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isAuthUser']),
  },
  methods: {
    goToDashboard() {
      const targetRouteName = this.isAuthUser ? 'dashboard' : 'auth';
      this.$router.push({ name: targetRouteName });
    },
  },
};
</script>

<style lang="sass" scoped>
.not-found
  display: flex
  flex-direction: column
  align-items: center
  text-align: center

.not-found__text
  margin: 12px 0 64px 0
</style>
